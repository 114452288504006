@import url(https://fonts.googleapis.com/css?family=Poppins:wght@500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: Poppins, -apple-system, system-ui, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  padding-top: 56px;
}

.width-100 {
  width: 100%;
}

.pointer-cursor {
  cursor: pointer;
}

.nav-logo-img {
  width: 80px;
}

.banner-bg {
  /* background: linear-gradient(rgba(162, 241, 248, 0.4), rgba(0, 86, 95, 0.85) ), url(/assets/images/banner_img.jpg); */
  background: linear-gradient(#863bf9, #6400ff)
}

.banner-heading {
  text-shadow: 0px -1px 4px #000000a3;
}

.video-container {
  width: 100%;
  /* background: #333333; */
  background: linear-gradient(rgba(0, 0, 0, 0.79), rgba(0, 0, 0, 0.88));
}

.video-player {
  width: 100%;
  padding: 0 1rem;
  /* height: 50vh; */
}

.important-text {
  font-size: 1.5rem;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

.iosSafari {
  position: relative;
  display: flex;
  justify-content: center;
}
.iosSafari div {
  width: 80%;
  outline: 0;
}
.iosSafari input {
  width: 100%;
  outline: 0;
  max-width: 800px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 600px) {
  .video-player {
    height: 500px !important;
  }
}

.lang-dropdown {
  width: 120px;
  margin: 0px 20px;
  padding: 0px 15px;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #fff 50%), linear-gradient(135deg, #fff 50%, rgba(0, 0, 0, 0) 50%);
  background-position: calc(100% - 5px) 10px, calc(100% - 0px) 10px, calc(100% - 1.5em) 3.5px;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.eko-btn {
  color: white;
  background-color: #6400ff;
  border-color: #6400ff;
}

.eko-btn:hover {
  color: white;
}

.bg-purple {
  background-color: #6400ff;
}

.bg-lavendar {
  background-color: #d2a0ff;
}
